// Generated by Framer (c93245f)

import { addFonts, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS, withFX } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
const MotionDivWithFX = withFX(motion.div);

const cycleOrder = ["vV6kyL4h7"];

const serializationHash = "framer-Q8vpO"

const variantClassNames = {vV6kyL4h7: "framer-v-1olkdyy"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const animation = {opacity: 0, rotate: 0, scale: 0.8, x: 8, y: 0}

const transition1 = {damping: 30, delay: 0, mass: 1, stiffness: 400, type: "spring"}

const animation1 = {opacity: 0, rotate: 0, scale: 0.8, transition: transition1, x: 8, y: 0}

const transition2 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "vV6kyL4h7", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const transforms = restProps.transformTemplate?.(undefined, "") ?? ""

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition2}><MotionDivWithFX {...restProps} __framer__animate={{transition: transition1}} __framer__animateOnce={false} __framer__enter={animation} __framer__exit={animation1} __framer__styleAppearEffectEnabled __framer__threshold={0.5} __perspectiveFX={false} __smartComponentFX __targetOpacity={1} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1olkdyy", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"vV6kyL4h7"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", ...style}} transformTemplate={(_, t) => `${transforms} perspective(1200px) ${t}`}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Q8vpO.framer-xz4wii, .framer-Q8vpO .framer-xz4wii { display: block; }", ".framer-Q8vpO.framer-1olkdyy { height: 720px; position: relative; width: 1080px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 720
 * @framerIntrinsicWidth 1080
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerJUakrNQJk: React.ComponentType<Props> = withCSS(Component, css, "framer-Q8vpO") as typeof Component;
export default FramerJUakrNQJk;

FramerJUakrNQJk.displayName = "bg-video";

FramerJUakrNQJk.defaultProps = {height: 720, width: 1080};

addFonts(FramerJUakrNQJk, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})